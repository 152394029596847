@charset "UTF-8";
/**
 * Main Style Entry Point
 */
/**
 * Vendors
 */
input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required {
  border: 1px solid #ededed !important;
  padding: 6px 15px !important;
  width: 50%;
  background-color: #ededed !important; }

input.wpcf7-form-control.wpcf7-text {
  border: 1px solid #ededed !important;
  padding: 6px 15px !important;
  width: 50%;
  background-color: #ededed !important; }

.contact-col2 {
  margin-top: 50px; }

label {
  width: 100% !important; }

textarea.wpcf7-form-control.wpcf7-textarea {
  border: 1px solid #ededed;
  width: 100%;
  background-color: #ededed !important; }

input.wpcf7-form-control.wpcf7-submit {
  background: #fe6467;
  padding: 2px 25px; }

/*== GENERAL CSS ==*/
.news_sec figure,
.news_sec figure * {
  text-decoration: none;
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  -ms-transition: all 250ms linear;
  -o-transition: all 250ms linear;
  transition: all 250ms linear; }

.service_section .srvc_pnl:hover i {
  -webkit-transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  transform: rotateY(360deg); }

body {
  padding: 30px; }

/*.flow-hide { overflow:hidden;}*/
figure {
  position: relative; }

.btn {
  min-width: 100px;
  padding: 10px 20px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  border: 0;
  border-radius: 0; }

.btn:hover {
  color: #000;
  background: #fff; }

.btn i {
  margin: 0 0 0 10px;
  font-size: 16px; }

/*=== BANNER CSS ===*/
#banner {
  width: 100%;
  text-align: center; }

#banner .artcl {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%; }

#banner .artcl h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  text-shadow: 2px 2px 10px #000;
  text-transform: uppercase; }

#banner .artcl p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-shadow: 2px 2px 10px #000; }

#banner .pgr {
  position: absolute;
  bottom: 20px;
  left: 0;
  z-index: 200;
  text-align: center;
  width: 100%; }

#banner .pgr span {
  background: #061323;
  border-radius: 50%;
  font-size: 0;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin: 0 2px;
  border: 1px solid #fff;
  cursor: pointer; }

#banner .pgr span.cycle-pager-active {
  background: #fff; }

#banner ul li {
  width: 100%;
  white-space: normal; }

#banner img {
  width: 100%; }

/*=== SECTION CSS ===*/
.section {
  padding: 10px 0; }

.section h2 {
  position: relative;
  color: #000;
  font-size: 35px;
  padding: 0 0 15px;
  margin: 0 0 20px;
  font-weight: 600;
  text-transform: uppercase; }

.section h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 80px;
  height: 1px;
  background: #000;
  content: '';
  margin: 0 auto; }

.section p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  margin: 0 0 15px; }

.news_sec {
  padding: 150px 0 10px; }

.news_sec .row {
  margin: 0 -5px; }

.news_sec .col-sm-6 {
  padding: 0 5px 10px; }

.news_sec h2 {
  font-size: 36px;
  color: #fff;
  padding: 0 0 15px;
  margin: 0 0 40px;
  border-bottom: 1px solid #41535a;
  text-align: center;
  text-transform: uppercase; }

.news_sec img {
  width: 100%;
  display: block; }

.news_sec figure {
  position: relative;
  background: #000; }

.news_sec figure:hover {
  cursor: pointer; }

.news_sec figure h3 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
  padding: 15px 20px 15px 15px;
  margin: 0;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  letter-spacing: 2px;
  width: 100%; }

.news_sec figure h3 small {
  display: block;
  clear: both;
  max-width: 100px;
  margin: 0 0 10px;
  font-size: 12px;
  padding: 5px;
  letter-spacing: 0px;
  font-weight: 600; }

.news_sec figure h3 span {
  display: block;
  font-size: 12px;
  font-style: italic;
  margin: 10px 0 0; }

.news_sec figure h3 span img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin: 0 5px 0 0;
  display: inline-block; }

.news_sec figure:hover h3 {
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 -50px 55px rgba(0, 0, 0, 0.7); }

.news_sec figure:hover img {
  opacity: 0.7; }

.video_icon {
  position: relative;
  display: block; }

.video_icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  margin: auto;
  background: url("/images/video-icon.png") no-repeat center center;
  z-index: 1; }

/*=== FOOTER CSS ===*/
.footer {
  padding: 30px 0;
  text-align: center;
  background: #000;
  margin: 80px 0 0; }

.footer p {
  color: #fff;
  margin: 0;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 300; }

.inrPage {
  display: block;
  position: relative;
  right: 0; }

.inrPage .inner-wraper {
  max-width: 100%;
  position: relative;
  overflow: visible;
  height: auto;
  padding: 30px 20px; }

.inrPage .inner-wraper img {
  width: 100%; }

.inrPage .main-bg {
  margin: 0; }

.inrPage .close_btn {
  display: none; }

#header #pull {
  display: none; }

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .video_icon:before {
    display: none !important; }
  body {
    padding: 10px; }
  .news_sec {
    padding: 50px 0 10px; }
  .news_sec figure h3 {
    position: relative;
    font-size: 18px; }
  .footer {
    margin: 20px 0 0; }
  .video_icon:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 74px;
    height: 74px;
    margin: auto;
    background: none !important;
    z-index: 1; }
  select#country1 {
    border: 1px solid;
    border-radius: 5px;
    float: left !important;
    padding: 5px 10px 5px 10px;
    margin-top: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .video_icon:before {
    display: none !important; }
  .news_sec {
    padding: 80px 0 50px; }
  .news_sec figure {
    max-height: 255px;
    overflow: hidden; }
  .video_icon:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 74px;
    height: 74px;
    margin: auto;
    background: none !important;
    z-index: 1; } }

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .news_sec figure {
    max-height: 350px;
    overflow: hidden; }
  .video_icon:before {
    content: "";
    display: none !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 74px;
    height: 74px;
    margin: auto;
    background: none !important;
    z-index: 1; } }

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .video_icon:before {
    content: "";
    display: none !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 74px;
    height: 74px;
    margin: auto;
    background: none !important;
    z-index: 1; } }

.social-icons ul li {
  display: inline-block;
  /* float: right; */
  /*padding: 0px 0px 0px 0px;*/ }

.social-icons ul {
  text-align: right; }

.social-icons ul li a {
  color: white;
  font-size: 12px; }

.content {
  width: 60%;
  float: left; }

.social-icons {
  width: 40%;
  float: left;
  padding: 30px 0px 30px 0px; }

.description {
  padding: 30px 0px 30px 0px; }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /*.navbar .navbar-nav {
    width: 89% !important;
    display: block;
    text-align: right;
    float: none;
    padding:0!important;
}*/
  .navbar .navbar-nav {
    width: 100%;
    display: block;
    text-align: left;
    float: none;
    padding: 0px 0px 0px 0px; }
  .navbar .navbar-nav > li > a {
    padding: 0;
    font-size: 10px;
    position: relative;
    display: block;
    font-weight: 600; }
  .navbar .navbar-nav > li {
    display: inline-block;
    padding: 0 6px;
    text-transform: uppercase;
    float: none;
    position: relative; } }

/**
 * Settings – used with preprocessors and contain font, colors definitions, etc.
 */
/**
 *  Main SASS Variables
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _OPS Open Sans
 *  _VAR Variables
 */
/**
 *  Main SASS Variables
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _GRD  Grid
 *  _COL  Colors
 *  _SIZ  Font Sizes
 *  _LIN  Line Height
 *  _ZID  Z-index
 *  _TRS  Transitions
 *  _BSS  Box shadows
 */
@lost gutter 35px;
@lost flexbox flex;
@lost cycle auto;
@lost clearing both;
/**
 * Tools
 */
/**
 *  Responsive Mixin
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _MIX  Mixins
 */
/**
 *  Display class helper
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _DSP  Display
 */
@media (max-width: 767px) {
  .hide--mobile {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .hide--tablet {
    display: none !important; } }

@media (min-width: 1024px) {
  .hide--desktop {
    display: none !important; } }

.clearfix::after {
  display: block;
  content: "";
  clear: both; }

/**
 * Layout
 */
/**
 *  Body base
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _GEN  Generic
 */
body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch; }
  body.--locked {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    transition: none; }
    @media (min-width: 1024px) {
      body.--locked {
        padding-right: 40px; } }

.bgVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
  /* Make the iframe keep an aspect ratio, and
    position it in the middle of its parent wrapper*/ }
  .bgVideo iframe {
    width: 100vw;
    height: 56.25vw;
    /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh;
    /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.preLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15000;
  opacity: 0;
  pointer-events: none;
  transition: 500ms 750ms opacity ease-in-out;
  background-color: #ccc; }
  .preLoader img {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 500ms opacity ease-in-out; }
  .preLoader--loading {
    opacity: 1;
    pointer-events: all; }
    .preLoader--loading img {
      opacity: 1; }

/**
 *  Header styling
 *
 *  @author kbastien vcroteau
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _GEN  Generic
 *  _MLE  Mobile legacy header
 */
#header {
  position: relative;
  left: 0;
  width: 100%;
  top: 0;
  background: #fff;
  padding: 15px 0;
  margin: 0 0 10px; }

#header .logo {
  display: inline-block;
  padding: 5px 0; }

#header .collapse {
  padding: 0; }

#header .navbar-toggler {
  display: none;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: 0 0;
  border: 1px solid transparent;
  border-radius: .25rem; }

#header .navbar-toggler span {
  height: 3px;
  background: #1a9505;
  width: 25px;
  display: block;
  margin-bottom: 3px;
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s; }

#header .navbar {
  padding: 13px 0 0;
  margin: 0;
  min-height: inherit; }

.navbar .navbar-nav {
  width: 100%;
  display: block;
  text-align: right;
  float: none;
  padding: 0px 0px 0px 0px; }

.navbar .navbar-nav > li {
  display: inline-block;
  padding: 0 15px;
  text-transform: uppercase;
  float: none;
  position: relative; }

.navbar .navbar-nav > li:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  background: #000;
  height: 10px;
  width: 2px; }

.navbar .navbar-nav > li:first-child:before {
  display: none; }

.navbar .navbar-nav > li > a {
  padding: 0;
  font-size: 13px;
  position: relative;
  display: block;
  font-weight: 600; }

@media only screen and (min-width: 0px) and (max-width: 767px) {
  #header #pull {
    display: inline-block;
    position: absolute;
    top: -45px;
    right: 15px;
    color: #000;
    font-size: 30px; }
  #header {
    text-align: left;
    overflow: hidden;
    padding: 10px 0; }
  #header .navbar {
    padding: 0; }
  #header .navbar .navbar-nav {
    margin: 0;
    text-align: left;
    padding: 0 !important; }
  .navbar .navbar-nav > li {
    padding: 5px 0;
    display: block;
    text-align: left;
    border-top: 1px solid #000; }
  .navbar .navbar-nav > li:before {
    display: none; } }

/**
 *  Color theme styling base
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _GEN  Generic
 */
body {
  background: #122831; }

.about_sec ul li > a,
a.btn,
.navbar .navbar-nav > li > a {
  color: #000; }

.about_sec h2,
.about_sec p,
.news_sec h3 a,
.post-panel .nw-tag,
.video_sec ul li h3 a {
  color: #fff; }

.about_sec ul li > a,
a.btn,
.post-panel .nw-tag,
.video_sec ul li h3 small {
  background: #75ffc6; }

.news_sec figure h3 small {
  color: #17bfad;
  background: #fff; }

/**
 * Components
 */
/**
 *  About component
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _GEN  Generic
 */
.about_sec {
  text-align: center; }
  .about_sec h2 {
    font-size: 50px;
    margin: 0 0 20px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 3px; }
  .about_sec p {
    font-size: 12px;
    margin: 0 auto;
    max-width: 680px;
    line-height: 25px;
    letter-spacing: 2px; }
  .about_sec ul {
    padding: 0 15px;
    margin: -60px 0 0; }
    .about_sec ul:after {
      clear: both;
      content: "";
      display: block; }
  .about_sec li {
    width: 25%;
    float: left;
    padding: 0 5px; }
    .about_sec li > a {
      line-height: 1.2;
      display: flex;
      align-items: center;
      padding: 8px 15px;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 600;
      position: relative;
      text-align: left; }
      .about_sec li > a:before {
        content: "\f105";
        display: block;
        font-family: FontAwesome;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        font-size: 17px; }
      .about_sec li > a:after {
        content: "";
        display: block;
        clear: both; }
      @media (min-width: 1024px) {
        .about_sec li > a:hover {
          background: #fff; } }
    .about_sec li i {
      width: 65px;
      height: 65px;
      border: 3px solid #000;
      border-radius: 50%;
      line-height: 60px;
      font-size: 28px;
      vertical-align: middle;
      margin: 0 20px 0 0;
      float: left;
      text-align: center; }
    .about_sec li i.fa-steam:before {
      border: 3px solid #000;
      border-radius: 50%;
      padding: 5px; }
  @media (max-width: 767px) {
    .about_sec h2 {
      font-size: 35px; }
    .about_sec ul li {
      width: 100%;
      float: none;
      margin: 0 0 10px; }
    .about_sec ul li > a i {
      width: 50px;
      height: 50px;
      line-height: 45px;
      font-size: 20px;
      margin: 0 10px 0 0; }
    .about_sec ul li > a {
      line-height: 50px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .about_sec {
      /*ul li > a { line-height: 25px; text-align: center; padding: 10px 2px; }*/
      /*ul li > a i { margin: 0 auto; float: none; display: block; text-align: center; }*/ }
      .about_sec ul li > a {
        line-height: 40px;
        text-align: left;
        padding: 10px 2px;
        font-size: 9px;
        letter-spacing: 0px; }
      .about_sec ul {
        padding: 0 5px;
        margin: -61px 0 0; }
      .about_sec ul li > a:before {
        content: "\f105";
        display: block;
        font-family: FontAwesome;
        position: absolute;
        right: 8px;
        font-size: 15px; }
      .about_sec ul li > a i {
        width: 40px;
        height: 40px;
        border: 3px solid #000;
        border-radius: 50%;
        line-height: 35px;
        font-size: 16px;
        margin: 0 5px 0 0;
        float: left;
        text-align: center; } }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .about_sec ul {
      margin: -30px 0 0; }
    .about_sec ul li > a i {
      width: 40px;
      height: 40px;
      line-height: 35px;
      font-size: 15px;
      margin: 0 10px 0 0; }
    .about_sec ul li > a {
      line-height: 40px;
      font-size: 11px; }
    .about_sec ul li > a i.fa-steam:before {
      border: 2px solid #000;
      padding: 4px; } }

/**
 *  Post Panel component
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _GEN  Generic
 */
.post-panel {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  z-index: 100;
  height: 100%;
  display: none; }
  .post-panel.active {
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 1s linear; }
  .post-panel .inner-wraper {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    max-width: 1020px;
    width: 100%;
    padding: 0 30px 0 70px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100vh; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .post-panel .inner-wraper {
        max-width: 100%; } }
  .post-panel .inner-wraper {
    box-shadow: -25px 0 50px #000; }
  .post-panel .close_btn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    height: 100%; }
  .post-panel .main-bg {
    margin: 0 -30px 0 0; }
  .post-panel .nw-tag {
    display: inline-block;
    min-width: 100px;
    text-align: center;
    margin: 35px 0;
    font-size: 11px;
    padding: 3px 5px;
    font-weight: 600;
    text-transform: uppercase; }
  .post-panel .clsBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; }
  .post-panel figure {
    margin: 0 0 15px; }
  .post-panel h3 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 30px;
    letter-spacing: 2px; }
  .post-panel h3 small {
    display: block;
    color: #9b9b9b;
    font-size: 12px;
    font-style: italic;
    margin: 15px 0; }
  .post-panel p {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 15px; }

/**
 *  Video box component
 *
 *  @author kbastien
 */
/**
 *  @author A&S
 *
 *  _TAB  Table of Contents
 *  _GEN  Generic
 *  _MOD  Video Modal
 */
.video_sec {
  padding-bottom: 0; }
  .video_sec img {
    width: 100%;
    display: block; }
  .video_sec ul {
    margin: 0 -5px; }
    .video_sec ul:after {
      clear: both;
      content: "";
      display: block; }
  .video_sec figure:hover {
    cursor: pointer; }
  .video_sec li {
    width: 25%;
    float: left;
    padding: 0 5px 10px;
    transition: all 250ms linear; }
    .video_sec li * {
      transition: all 250ms linear; }
    .video_sec li figure {
      position: relative;
      background: #000; }
    .video_sec li a {
      display: block; }
    .video_sec li:first-child {
      width: 50%; }
      .video_sec li:first-child .video_sec--title {
        font-size: 50px;
        line-height: 53px;
        padding-left: 25px; }
    @media (min-width: 1024px) {
      .video_sec li:hover {
        cursor: pointer; }
        .video_sec li:hover figure {
          cursor: pointer; }
          .video_sec li:hover figure h3 {
            background: rgba(0, 0, 0, 0.7);
            box-shadow: 0 -50px 55px rgba(0, 0, 0, 0.7); }
          .video_sec li:hover figure img {
            opacity: 0.5; } }
  .video_sec--title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 15px 25px 15px 15px;
    margin: 0;
    color: #fff;
    text-shadow: 0px 1px 0px #000;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  .video_sec h3 small {
    display: block;
    clear: both;
    max-width: 110px;
    background: #72fdbf;
    margin: 0 0 10px;
    font-size: 10px;
    padding: 5px;
    color: #000;
    text-shadow: 0 0 0;
    font-weight: 600;
    letter-spacing: 2px; }
  @media (max-width: 767px) {
    .video_sec ul li,
    .video_sec ul li:first-child {
      width: 100%; }
    .video_sec ul li:first-child h3 {
      font-size: 24px;
      padding-left: 15px; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .video_sec ul li h3 {
      font-size: 15px;
      padding: 10px;
      line-height: 20px; }
    .video_sec ul li:first-child video_sec--title {
      font-size: 35px;
      line-height: 35px; } }

.video-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: none; }

.video-panel .inner {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  max-width: 700px;
  margin: auto;
  background: #333;
  padding: 5px; }

.video-panel .inner iframe {
  width: 100%;
  height: auto;
  min-height: 400px; }

.mVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0ms ease-in-out 500ms; }
  .mVideo__wrapper {
    position: relative; }
  .mVideo__content {
    cursor: default;
    position: relative;
    opacity: 0;
    transform: scale(0);
    will-change: opacity, transform;
    transition: all 250ms linear;
    z-index: 1;
    max-height: 90vh;
    max-width: 1024px;
    padding: 0 20px;
    width: 100%;
    z-index: 9; }
    .mVideo__content img {
      max-height: 80vh;
      max-width: 80vw; }
  .mVideo__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    will-change: opacity;
    transition: all 250ms linear; }
  .mVideo--active {
    opacity: 1;
    pointer-events: all;
    transition: all 0ms ease-in-out 0ms; }
    .mVideo--active .mVideo__content {
      opacity: 1;
      transform: scale(1); }
    .mVideo--active .mVideo__bg {
      opacity: 0.9; }
  .mVideo__caption {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 1024px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
    text-align: left;
    padding: 10px 0px; }
    .mVideo__caption:focus {
      outline: none; }
    .mVideo__caption h3 {
      color: white;
      font-size: 15px;
      padding-left: 0; }
    .mVideo__caption p {
      max-width: 850px;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px; }
  .mVideo__close {
    position: absolute;
    top: 0;
    right: 20px;
    background: #333;
    z-index: 9;
    width: 30px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff; }

.js-video--youtube .js-videoplayer--wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }
  .js-video--youtube .js-videoplayer--wrapper iframe, .js-video--youtube .js-videoplayer--wrapper object, .js-video--youtube .js-videoplayer--wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/**
 * Pages
 */
